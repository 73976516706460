import React from 'react';
import Header from './Header';

const Tenders = () => {
  return (
    <div>
      <Header/>
     <h1>Tenders</h1> 
      
      </div>
  )
}

export default Tenders