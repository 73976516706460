import React from 'react';
import Bidlist from './Bidlists'

const Project = () => {
  return (
    <div>
      
      <Bidlist/>
    </div>
  )
}

export default Project